import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

import { AuthService } from '@io-provetech/auth';
import { RoutesConstants } from '@io-provetech/models';
import { ToastService } from '@io-provetech/shared-ui'

@Component({
  selector: 'io-provetech-signin-container',
  templateUrl: './signin-container.component.html',
  styleUrls: ['./signin-container.component.scss'],
})
export class SigninContainerComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {}

  /**
   * This method is an event handler for shared sign in component
   * 
   * @param $event - includes email and password params for sign in
   */
  signin($event: { email: string, password: string }) {
    const { email, password } = $event;
    console.log(email, password);
    this.authService
      .signInwithEmailPassword(email, password)
      .pipe(
        catchError(error => {
          this.toastService.showWarning(error.message);
          return throwError(() => error)
        })
      )
      .subscribe(() => {
        this.router.navigate([
          RoutesConstants.admin.mainRoute +
          '/' +
          RoutesConstants.admin.manufacturers.route
        ])
      });
  }
}
