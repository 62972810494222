import { Component } from '@angular/core';
import { switchMap } from 'rxjs';

import { PingService, PollingService } from '@io-provetech/utils/polling-service';

@Component({
  selector: 'io-provetech-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /** The construcor */
  constructor(
    private pingService: PingService,
    private pollingService: PollingService
  ) {}

  ngOnInit(): void {
    this.pingService
      .ping()
      .pipe(switchMap(() => this.pollingService.getPollingStream()))
      .pipe(switchMap(() => this.pingService.ping()))
      .subscribe();
  }
}
