import { Route } from '@angular/router';

import { RoutesConstants } from '@io-provetech/models';
import { ErrorPageComponent } from '@io-provetech/shared-ui';

import { SigninContainerComponent } from './auth/signin-container/signin-container.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: RoutesConstants.admin.login.route,
    pathMatch: 'full',
  },
  {
    path: RoutesConstants.admin.login.route,
    component: SigninContainerComponent,
    title: RoutesConstants.admin.login.title,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];
