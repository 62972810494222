import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  API_TOKEN,
  ErrorHandlerInterceptor,
  ErrorHandlerModule,
  ErrorHandlerService,
} from '@io-provetech/utils/error-handler';
import {
  HEALTH_CHECK_URL,
  KEEP_ALIVE_TOKEN,
  UtilsPollingModule,
} from '@io-provetech/utils/polling-service';
import { SharedUiModule } from '@io-provetech/shared-ui';
import { AuthService } from '@io-provetech/auth';
import { API_URL, UtilsHttpModule } from '@io-provetech/utils/http-service';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { SigninContainerComponent } from './auth/signin-container/signin-container.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninContainerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    RouterModule,
    
    SharedUiModule,
    ErrorHandlerModule,
    UtilsHttpModule,
    UtilsPollingModule,
  ],
  providers: [
    { provide: API_TOKEN, useValue: environment.bugsnag.apiKey },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    AuthService,
    { provide: API_URL, useValue: environment.httpApiURL },
    { provide: KEEP_ALIVE_TOKEN, useValue: environment.keepAlive },
    { provide: HEALTH_CHECK_URL, useValue: environment.healthCheckURL }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
