export const environment = {
  keepAlive: true,
  httpApiURL: 'https://admin.api.provenancetechnology.dev/v3',
  healthCheckURL: 'https://admin.api.provenancetechnology.dev',
  bugsnag: {
    apiKey: 'd4a7babc930bc6a1b8bb62810f51cda0'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDFf4p6om-MtDsLI3bt1Fz-TytQAiUQ11Q",
    authDomain: "provtech-dev--firebase--admin.firebaseapp.com",
    projectId: "provtech-dev--firebase--admin",
    storageBucket: "provtech-dev--firebase--admin.appspot.com",
    messagingSenderId: "557834283400",
    appId: "1:557834283400:web:9b5d2533d5e0aed57df23d",
    measurementId: "G-EQBFK3QSYZ"
  }
};
